// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("channels")

import "@hotwired/turbo-rails";
Turbo.session.drive = false;

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
// Ces lignes de code recherchent tous les fichiers JavaScript dans le répertoire controllers et les chargent comme contrôleurs Stimulus. Cela signifie que vous n'avez pas besoin de maintenir manuellement une liste de tous vos contrôleurs dans index.js.
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import "jquery";
import "popper.js";
import "bootstrap";
import "./cookieHelper";
import "leaflet/dist/leaflet";
// const L = window['L'];
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster.js";
import "leaflet-control-geocoder";
import 'leaflet.locatecontrol' // Import plugin
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css' // Import styles
// https://github.com/Leaflet/Leaflet.markercluster/issues/874

// pour importer les icones de leaflet - pb, l'adresse eest bonne mais le dossier est vide ?
// L.Icon.Default.imagePath = "images/";

// import "./location";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("packs/cookieHelper")
// require("packs/location")


