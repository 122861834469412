// app/javascript/controllers/modal_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dialog"];

  loadContent(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;
    const dialog = this.dialogTarget;

    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((html) => {
        dialog.innerHTML = html;
        dialog.showModal(); // Ouvre le <dialog>
      })
      .catch((error) => console.log(error));
  }

  close() {
    this.dialogTarget.close();
  }
}
