//importer stimulus
import { Controller } from "@hotwired/stimulus";

//exporter la classe
export default class extends Controller {
  static values = {liveStations: Array, markers: Array};
   // Timer property to store the interval ID
  timer = null;

  initialize() {
    this.markers = [];
  }

  updateLiveStations() {
  let center = this.map.getCenter();
  let url = "/update_balises";  // Remplacez par le chemin de votre nouvel endpoint

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": this.getMetaValue("csrf-token"),
    },
    body: JSON.stringify({
      latitude: center.lat,
      longitude: center.lng,
    }),
  })
    .then(response => response.json())
    .then(data => {
      this.liveStationsValue = data;
      this.displayLiveStations();
    });
  }

  connect() {
    // console.log(this.liveStationsValue)
    this.map = window.map;

    this.liveStationsValue.forEach((station) => {
      this.addStationToMap(station);
    });

    this.map.on("dragend", () => {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.updateLiveStations();
      }, 2000);
    });

    // ecoute l'evenement updateLocation déclenché par la bibliotheque leaflet geocoder
    document.addEventListener("updateLocation", this.updateLocation.bind(this));
    document.addEventListener(
      "searchLocationChange",
      this.updateLocationSearch.bind(this)
    );

    // Set up timer to update live stations every minute (60000 milliseconds)
    this.timer = setInterval(() => {
      this.updateLiveStations();
    }, 120000);

    document
      .getElementById("windSpeedSelect")
      .addEventListener("change", this.changeWindSpeedUnit.bind(this));

    this.setInitialWindSpeedUnit();
  }

  updateLocationSearch() {
    this.updateLiveStations();
  }

  addStationToMap(station) {
    var wind_speed = this.selectWindSpeed(station);
    var wind_directions = this.selectWindDir(station);
    var infoContent = this.selectInfo(station);

    var marker = L.marker([station.latitude, station.longitude], {
      icon: this.windMarker,
    })
      .bindTooltip(`${wind_speed}`, {
        permanent: true,
        direction: "center",
        className: "station",
      })
      .bindPopup(`${infoContent}`)
      .addTo(this.map);

    var iconElement = marker._icon;

    var svgElement = iconElement.querySelector('svg');
    svgElement.style.transform = `rotate(${wind_directions+180}deg)`

    var layer5 = iconElement.querySelector('#layer5');
    layer5.classList.add(`s${wind_speed}`);
    this.markers.push(marker);
  }

  selectWindSpeed(obj) {
  //balise ffvl
  if ("vitesseVentMoy" in obj) {
    return obj.vitesseVentMoy;
  }
  if ("wind_speed_avg" in obj) {
    return obj.wind_speed_avg;
  }
  //metar
  if ("wspd" in obj) {
    return obj.wspd;
  }
  return null;
}

selectWindDir(obj) {
  //balise ffvl
  if ("directVentMoy" in obj) {
    return obj.directVentMoy;
  }
  if ("wind_heading" in obj) {
    return obj.wind_heading;
  }
  //metar
  if ("wdir" in obj) {
    return obj.wdir;
  }
  return null;
}

selectInfo(obj) {
  //metar
  if ("rawOb" in obj) {
    let info = `<p>${obj.name} <br> ${obj.wspd} knots // temperature: ${obj.temp} <br>  ${obj.rawOb}</p>`; ;
    return info;
  }
  //balise ffvl
  if ("remarques" in obj) {
    let info = `<p>${obj.nom} <br> Vmin: ${obj.vitesseVentMin} / Vmoy:${obj.vitesseVentMoy} / Vmax: ${obj.vitesseVentMax} Kmh / temperature: ${obj.temperature} <br>  ${obj.remarques} <br> <a href=${obj.url} target="_blank"> Link to the station </a> </p>`;
    return info;
  }
  //Piou piou
  if ("pioupiou_id" in obj) {
    let info = `<p>${obj.name} <br> Vmin: ${obj.wind_speed_min} / Vmoy:${obj.wind_speed_avg} / Vmax: ${obj.wind_speed_max} Kmh / <br> <a href=https://www.openwindmap.org/${obj.pioupiou_id} target="_blank" > Link to the station </a> </p>`;
    return info;
  }
  return null;
};

windMarker = L.divIcon({
  html: `
<svg
   viewBox="0 0 200 200"
   id="svg2"
   version="1.1">
  <g
     id="layer1"
     transform="translate(0,-852.36216)"
     style="display:inline">
    <circle
       r="60.000023"
       cy="952.36218"
       cx="99.999992"
       id="circle924"
       style="opacity:1;fill:#444444;fill-opacity:1;stroke:none;stroke-width:9.375;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
    <path
       style="fill:#444444;fill-opacity:1;stroke:none;stroke-width:0.9375px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 99.999396,852.36216 c -20,0 -30.000004,9.99939 -30.000004,9.99939 l -26.151124,68.82019 56.151128,21.17982 56.152954,-21.17982 -26.15295,-68.82019 c 0,0 -10.00001,-9.99939 -30.000004,-9.99939 z"
       id="path894" />
    <g
       id="g898" />
  </g>
  <g
     id="layer2"
     style="display:inline">
    <path
       transform="translate(0,-852.36216)"
       id="path868"
       d="m 99.999396,867.36216 c -12.999404,0 -19.998784,6.00037 -19.998784,6.00037 l -25.001221,68.99963 c 11.323949,-5.00804 33.93329,-9.9175 45.000005,-9.99939 11.066714,0.0819 33.676054,4.99135 45.000004,9.99939 l -25.00123,-68.99963 c 0,0 -6.99938,-6.00037 -19.998774,-6.00037 z"
       style="display:inline;fill:#f0f0f0;fill-opacity:1;stroke:none;stroke-width:0.9375px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
    <circle
       transform="translate(0,-852.36216)"
       style="display:inline;opacity:1;fill:#f0f0f0;fill-opacity:1;stroke:none;stroke-width:9.375;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
       id="path886"
       cx="99.999992"
       cy="952.36218"
       r="45.000023" />
  </g>
  <g
     id="layer5"
     style="display:inline">
    <path
       style="fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
       d="m 106.66602,34.132812 c -14.932701,0 -21.332167,3.19987 -28.798832,8.533204 L 68.115234,69.84375 A 53.333356,53.333356 0 0 0 53.333984,106.66602 53.333356,53.333356 0 0 0 106.66602,160 53.333356,53.333356 0 0 0 160,106.66602 53.333356,53.333356 0 0 0 145.25195,69.941406 l -9.78515,-27.27539 c -7.46668,-5.333335 -13.8681,-8.533203 -28.80078,-8.533204 z m 0,24.533204 a 48.000023,48.000023 0 0 1 38.4082,19.294922 48.000023,48.000023 0 0 1 0.002,0 48.000023,48.000023 0 0 1 9.58985,28.705082 48.000023,48.000023 0 0 1 -48,48 48.000023,48.000023 0 0 1 -48.000004,-48 48.000023,48.000023 0 0 1 9.601562,-28.705082 48.000023,48.000023 0 0 1 0.002,0 48.000023,48.000023 0 0 1 3.025391,-3.65625 48.000023,48.000023 0 0 1 0.08594,-0.09375 48.000023,48.000023 0 0 1 3.25,-3.212891 48.000023,48.000023 0 0 1 0.275391,-0.244141 48.000023,48.000023 0 0 1 3.378906,-2.730468 48.000023,48.000023 0 0 1 0.472656,-0.335938 48.000023,48.000023 0 0 1 3.429688,-2.246094 48.000023,48.000023 0 0 1 0.75,-0.43164 48.000023,48.000023 0 0 1 3.384766,-1.761719 48.000023,48.000023 0 0 1 1.044922,-0.466797 48.000023,48.000023 0 0 1 3.279296,-1.308594 48.000023,48.000023 0 0 1 1.375,-0.457031 48.000023,48.000023 0 0 1 3.095704,-0.888672 48.000023,48.000023 0 0 1 1.703124,-0.382812 48.000023,48.000023 0 0 1 2.886719,-0.523438 48.000023,48.000023 0 0 1 2.021489,-0.246094 48.000023,48.000023 0 0 1 2.98046,-0.238281 48.000023,48.000023 0 0 1 1.95704,-0.07031 z"
       transform="scale(0.93750002)"
       id="path1023-1" />
  </g>
</svg>`,
  className: "wind-marker-icon",
  iconSize: [60, 60],
  iconAnchor: [30, 30],
});



 getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }

  displayLiveStations() {
    // Effacez tous les marqueurs actuellement affichés.
    this.markers.forEach((marker) => marker.removeFrom(this.map));
    // Réinitialisez le tableau de marqueurs.
    this.markers = [];
    this.liveStationsValue.forEach((station) => {
      this.addStationToMap(station);
    });
  }

   // ecoute l'evenement updateLocation déclenché par la bibliotheque leaflet geocoder
  updateLocation() {
    this.updateLiveStations();
  }

  changeWindSpeedUnit(event) {
  const newUnit = event.target.value;
  const url = "/update_balise_wind_unit"; // Replace with your Rails route for updating the user model

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": this.getMetaValue("csrf-token"),
    },
    body: JSON.stringify({
      live_station_wind_unit: newUnit
    })
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(response);
    }
  })
  .then(data => {
    this.updateLiveStations();
  })
  .catch(error => console.error("Error updating wind unit:", error));
  }


  disconnect() {
    // Clear the timer when the controller is disconnected
    clearInterval(this.timer);
    document.removeEventListener("updateLocation");
  }

  setInitialWindSpeedUnit() {
  // Get wind speed unit either from cookie or current_user
  var windSpeedUnit = this.getWindSpeedUnit();

  // Preselect the correct option in the dropdown
  var selectElement = document.querySelector("#windSpeedSelect");
  if (selectElement) {
    selectElement.value = windSpeedUnit;
  }
  }

  getWindSpeedUnit() {
    // Try to get the wind speed unit from the cookie
    var windSpeedUnit = getCookie("balise_wind_unit");

    // Default to "kmh" if not found anywhere
    return windSpeedUnit || "kmh";
  }


}

